import './WhatToDo.scss';

import cash from '../../assets/cash.jpeg';
import { Paragraph } from '../../UI/Paragraph/Paragraph';
import { Heading1 } from "../../UI/Heading1/Heading1";
import { Heading2 } from "../../UI/Heading2/Heading2";
import { Heading3 } from '../../UI/Heading3/Heading3';

interface WhatToDoProps {

}










export const WhatToDo: React.FC<WhatToDoProps> = () => {
    return <div className="WhatToDo">
        <Heading3>“Úžasný! Takže co mám dělat?”</Heading3>
        <Heading2>Protože v <i>Česku</i> tohle agentury <span className='underline'>nedělají</span>, můžete si to <b>udělat sami!</b></Heading2>
        <Heading2><i>Běžte, a <b>vyzkoušejte to!</b> <span className='underline'>Jak na to?</span></i></Heading2>
        <Heading1>Jak na to #1</Heading1>
        <Heading2>Potřebujete <i>poznat</i> vašeho <span className='underline'>IDEÁLNÍHO zákazníka</span>. Ale <i>tentokrát</i> <b><span className='underline'>opravdu</span> do hloubky!</b></Heading2>
        <Paragraph>Vytvořte si <b>avatar ideálního zákazníka</b>.</Paragraph>
        <Paragraph>Proč nakupuje <i>zrovna</i> od vás?</Paragraph>
        <Paragraph>Co je jeho <i>největší</i> problém?</Paragraph>
        <Paragraph>Jaké jsou jeho <span className='underline'>frustrace</span> při řešení problému <i>bez vás?</i></Paragraph>
        <Paragraph>Jaké <i><b>emoce</b> cítí</i>, když je <i>připravený koupit?</i></Paragraph>
        <Heading2>Nejlépe se <b><i>zeptejte přímo jich</i></b>. <span className='underline'>Zavolejte</span> jim. Dejte na web <span className='underline'>dotazník</span>.</Heading2>
        <Heading1>Jak na to #2</Heading1>
        <Heading2>Připravte <span className='underline'>NEODOLATELNOU</span> nabídku!</Heading2>
        <Paragraph>Už víte, co <span className='underline'>přesně</span> váš ideální zákazník <i>chce</i>.</Paragraph>
        <Paragraph><b>Tak mu to dejte!</b></Paragraph>
        <Paragraph>Bez řečí kolem, jen to, co <b><i>nejvíc potřebuje</i></b>.</Paragraph>
        <Paragraph>Nic <i>víc</i>, nic <i>míň!</i></Paragraph>
        <Paragraph>A jestli <span className='underline'><i>opravdu</i></span> nemůžete vytvořit <i>NEODOLATELNOU</i> nabídku, tak <b>buďte kreativní!</b></Paragraph>
        <Paragraph>Vymyslete, jak vaši nabídku <b>napozicovat</b>, aby <b><i><span className='underline'>vyzněla úžasně!</span></i></b></Paragraph>
        <Heading1>Jak na to #3</Heading1>
        <Heading2>Upravte <b>reklamy</b> a <b>web</b>. </Heading2>
        <Paragraph>Ať je to <b><i>emoční</i></b>.</Paragraph>
        <Paragraph>Ať se do toho <span className='underline'>návštěvník vcítí</span>.</Paragraph>
        <Paragraph>Pomáhejte jim <i>řešit jejich  <b>problém</b></i>, <span className='underline'>aniž</span> by vám platili.</Paragraph>
        <Paragraph>Jasně je proveďte <i><span className='underline'>až k akci</span></i>.</Paragraph>
        <Paragraph>Ideálně vytvořte <span className='underline'><b>nový web jako trychtýř</b></span>, který bude mít <b><i>jediný účel</i></b> - <span className='underline'>udělat z návštěvníka zákazníka</span>!</Paragraph>
        <Heading2 className='WhatToDo__testing'>Pak už stačí jen <b><span className='underline'>testovat</span></b> a <b><span className='underline'>systematicky optimalizovat</span></b> <i>konverzní poměr</i>.</Heading2>
    </div>
}

export default WhatToDo;