import './EasyThing.scss';
import trees from '../../assets/trees.jpg';

import { Heading1 } from "../../UI/Heading1/Heading1";
import { Heading2 } from "../../UI/Heading2/Heading2";
import { Heading3 } from '../../UI/Heading3/Heading3';
import { Paragraph } from '../../UI/Paragraph/Paragraph';

interface EasyThingProps {

}













export const EasyThing: React.FC<EasyThingProps> = () => {
    return <div className="EasyThing SkewSection">
        <div className="EasyThing__background">
            <img src={trees}></img>
        </div>
       <Paragraph>Jednoduchá věc:</Paragraph>
       <Paragraph>Byznysy chtějí <b>růst</b> a k tomu potřebují <span>nakupující</span>.</Paragraph>
       <Paragraph>Nejefektivnější cesta, jak je získat, je <b>reklama</b>.</Paragraph>
       <Paragraph>V zahraničí CENY ZA REKLAMU stouply za poslední rok o <span><b>20</b>%!</span></Paragraph>
       <Paragraph>Stoupají už dlouho, stoupají dál a ještě dlouho se nezastaví!</Paragraph>
       <Paragraph>Takže se muselo přijít s metodou, jak udělat <b>reklamy <i>efektivnější...</i></b></Paragraph>
       <Paragraph>A O TOM SE TU BUDEME BAVIT.</Paragraph>
    </div>
}

export default EasyThing;