import './WhatForMe.scss';

import cash from '../../assets/cash.jpeg';

import { Heading1 } from "../../UI/Heading1/Heading1";
import { Heading2 } from "../../UI/Heading2/Heading2";
import { Heading3 } from '../../UI/Heading3/Heading3';
import { Paragraph } from '../../UI/Paragraph/Paragraph';
import { SetStateAction, Dispatch } from 'react';

interface WhatForMeProps {
    setIsOrderActive: Dispatch<SetStateAction<boolean>>
}

export const WhatForMe: React.FC<WhatForMeProps> = ({setIsOrderActive}) => {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();
    let ddString = dd.toString();
    let mmString = mm.toString();

    if (dd < 10) {ddString = '0' + dd};
    if (mm < 10) {mmString = '0' + mm};

    const formattedToday = dd + '.' + mm + '.' + yyyy;
    return <div className="WhatForMe">
        <Heading3>“Skvěle. Co pro mě uděláte?”</Heading3>
        <Heading2><b>VŠECHNO</b> od definování <b><span className='underline'>ideálního</span> zákazníka</b> po další a <i><span className='underline'>další</span></i> optimalizace pro <i>více</i> a <i><span className='underline'>více prodejů</span></i>.</Heading2>
        <Heading2>Budeme postupovat <b>systematicky</b> a <b><span className='underline'>vědecky</span></b>.</Heading2>
        <Paragraph>Na začátku <span className='underline'>zjistíme maximum</span> o vaší <b>cílové skupině.</b></Paragraph>
        <Paragraph>Vyplníme <b><i>vlastní</i>&nbsp;<span className='underline'>PRODEJNÍ PŘEDLOHU</span></b> (něco jako byznys plán, ale zaměřený na prodeje),</Paragraph>
        <Heading2 className='marginTop'>pochopíme <b><span className='underline'>nejhlubší</span> motivace, <span className='underline'>tajné</span> chtíče</b> a <b>vzorce chování</b> <span className='underline'>vašeho zákazníka</span>.</Heading2>
        <Heading3><i><b>KLAP!</b> Pastička</i> se zavřela. <span className='underline'>Přesvědčili</span> jsme ho <i><span className='underline'>nakoupit</span></i>, i když před tím <b>nakupovat <i>nechtěl</i></b>.</Heading3>
        <Paragraph>Můj tým <i><span className='underline'>udělá všechno</span></i>.</Paragraph>
        <Paragraph>
        Po <b>prodejní předloze</b> vytvoří<br></br>
        😈 <b>reklamy</b>,<br></br>
        😈 <b>trychtýře</b>.
        </Paragraph>
        <Paragraph>Využije klidně <i><span className='underline'>všechny</span></i> kanály. Když to bude <i>potřeba</i>, i</Paragraph>
        <Paragraph>
            😈 <b>e-mailový marketing</b>, <i>(e-mail je jeden z <span className='underline'>nejprofitabilnějších</span> kanálů, KDYŽ se udělá <span className='underline'>správně</span>!)</i>
        </Paragraph>
        <Paragraph>
            😈 <b>SMS marketing</b> <i>(v Česku zatím <span className='underline'>velmi</span> NEDOCENĚNÝ)</i>
        </Paragraph>
        <Heading2 className='marginTop'><i>Změříme</i> <b>DATA</b>. <span className='underline'>Všechno</span> <i>musí</i> být založeno na <i>datech</i>, jinak to <b><span className='underline'>nebude fungovat</span></b>.</Heading2>
        <Paragraph>A třeba zjistíme, že váš byznys potřebuje další <i>“hacky”</i>.</Paragraph>
        <Paragraph>
            😈 Optimalizaci pro vyhledávače? Aby vám <span className='underline'>zdarma</span> chodili návštěvníci na web?<br></br>
            😈 <i>Ohřátí</i> návštěvníka, aby z ledově studeného <span className='underline'>neznalce</span> vašeho řešení byl váš <b><i><span className='underline'>rozžhavený milovník</span>?</i></b><br></br>
            😈 Komplikovaný, ale <span className='underline'>dlouhodobě</span> ZATRACENĚ účinný, vícekrokový proces <b>konverze?</b><br></br>
        </Paragraph>
        <Paragraph>A...</Paragraph>
        <Heading1>GROWTH HACKING</Heading1>
        <Paragraph><b>Growth hacking.</b></Paragraph>
        <Paragraph>Tahle <i>disciplína</i> vznikla pro <span className='underline'>startupy</span>, co <b>musely přežít</b> na západním trhu s <b><span className='underline'>nicotným rozpočtem</span></b>.</Paragraph>
        <Paragraph>Je to <span className='underline'>set technik a přístupů</span>, které můžou mít <i>FENOMENÁLNÍ</i> výsledky za <b><span className='underline'>mizivou</span></b> investici.</Paragraph>
        <Heading2 className='marginTop'><b>Teď ta <span className='underline'>hlavní</span> otázka...</b></Heading2>
        <Heading3 className='architect'>“Je to pro mě?”</Heading3>
        <Paragraph>To, o <i>čem se bavíme</i>, jsou <span className='underline'>UNIVERZÁLNÍ</span> techniky.</Paragraph>
        <Paragraph>KAŽDÝ člověk je <b><span className='underline'>emoční</span></b> bytost.</Paragraph>
        <Paragraph>Takže to pro vás <b>bude fungovat</b>, <i>pokud</i> u vás nakupují <b>lidé</b>.</Paragraph>
        <Paragraph>Ať už <b>B2B</b>, nebo <b>B2C</b>.</Paragraph>
        <Paragraph>
            Funguje to pro<br></br>
            ➡️ prodej <b>fyzických</b> produktů,<br></br>
            ➡️ <b>služby</b> <span className='underline'>na internetu</span>,<br></br>
            ➡️ <b>fyzické služby</b>,<br></br>
            ➡️ <b><span className='underline'>konzultační</span></b> služby,<br></br>
            ➡️ <b><span className='underline'><i>informační</i></span></b> produkty,<br></br>
            ➡️ finanční <b><span className='underline'>poradce</span></b>,<br></br>
            ➡️ <b>aplikace,</b><br></br>
            ➡️ a <b><i>další</i></b>.
        </Paragraph>
        <Heading2 className='marginTop'><i>Spustíte <b>novou fázi</b> <span className='underline'>vašeho byznysu</span>, nebo <b><span className='underline'>zůstanete, kde jste</span></b>?</i></Heading2>
        <Heading3 className='architect'>“Kdy uvidím výsledky?”</Heading3>
        <Heading2>Chápu, výsledky chcete <b>hned.</b></Heading2>
        <Paragraph><i>Co</i> se <b>musí stát</b>, než <span className='underline'>přijdou výsledky</span>?</Paragraph>
        <Paragraph>Musíme <b>vytvořit plán</b> <i>(cca 2 dny až týden)</i>.</Paragraph>
        <Paragraph><span className='underline'>Vyvineme</span> všechno, co budete <b><span className='underline'>KRITICKY</span> potřebovat</b> - <i>týden, dva, když toho bude hodně, tři.</i></Paragraph>
        <Paragraph><b>Spustíme reklamy</b> - bohužel algoritmy reklam se <span className='underline'>musí učit</span>. Než se <i>algoritmus zaučí</i>, trvá to <i>2-4 týdny</i>.</Paragraph>
        <Paragraph><b><span className='underline'>V tuhle chvíli to už funguje.</span></b></Paragraph>
        <Paragraph>Následují <i><span className='underline'>optimalizace</span></i>, které zvyšují <span className='underline'></span>jakékoli <span className='underline'>výsledky</span>, kterých jsme zatím dosáhli.</Paragraph>
        <Heading3 className='architect marginTop'>“Zní to všechno skvěle, ale kolik mě to bude stát?”</Heading3>
        <Heading2>Přináší to <b><span className='underline'>VELKÉ VÝSLEDKY</span></b> = stojí to <b><span className='underline'>velké peníze</span></b>.</Heading2>
        <Heading2>Za <b><span className='underline'><i>nejjednoduší</i></span></b> provedení...</Heading2>
        <Paragraph>Kdy se <i>udělá</i>:<br></br>
            ○ <i>prodejní</i> byznys <span className='underline'>plán</span><br></br>
            ○ jeden <span className='underline'>trychtýř</span>  <i>(textace + design + vývoj)</i><br></br>
            ○ <span className="underline">reklamy</span>
        </Paragraph>
        <Paragraph>se zahraničním agenturám platí třeba <b><i>200.000Kč + 10% výdělku.</i></b></Paragraph>
        <Heading2 className='marginTop'>Nejde to <b><i>levněji?</i></b></Heading2>
        <Paragraph><i>Zaplatíte</i> sice 200.000Kč + 10%,</Paragraph>
        <Paragraph>ale získáte <b><i>předvídatelný “stroj”</i></b> na peníze.</Paragraph>
        <Paragraph>Vložíte korunu, vrátí se <i>dvě</i>.</Paragraph>
        <Paragraph>Konečně budete mít ten <i>příjem</i>, který chcete.</Paragraph>
        <Paragraph>A bude to mnohem <span className="underline">jistější</span> příjem.</Paragraph>
        <Paragraph>Budete moct <b><i>dál růst</i></b>, nebo si víc <b><i>užívat</i></b>.</Paragraph>
        <Paragraph>Budete zase <i><span className="underline">lepší verzí sebe</span></i> sama.</Paragraph>
        <Paragraph>Budete mít zase <i>víc práce za sebou</i>.</Paragraph>
        <Paragraph>Budete na sebe opět moct být <b><span className="underline">právem hrdí</span></b>.</Paragraph>
        <Heading2 className='marginTop'><i>Kdybych</i> měl někam <i>investovat</i> svou <i><span className="underline">poslední korunu</span></i>, bude to <b><span className="underline">do marketingu</span></b>.</Heading2>
        <Paragraph><span className="secondary"><b>Do marketingu</b>, co</span></Paragraph>
        <Paragraph>
            <span className="secondary"><b><i><span className="underline">staví na datech</span></i></b>,</span><br></br>
            <span className="secondary">umí <b><i><span className="underline">dostat lidi k nákupu</span></i></b>,</span><br></br>
            <span className="secondary">a nebude mít problém <b><i><span className="underline">využít i nekonvenční praktiky</span></i></b>.</span>
        </Paragraph>
        <Paragraph>A <i>prosím</i>, nechápejte mě špatně.</Paragraph>
        <Paragraph><b>Růstový marketing</b><i> (to, co děláme)</i> </Paragraph>
        <Paragraph><span className="underline">nemá</span> lidi <i>slepě přesvědčit, dotlačit</i> k nákupu...</Paragraph>
        <Paragraph><span className="underline">Nechce</span> lidi <b>obírat o peníze</b>.</Paragraph>
        <Paragraph>Růstový marketing má <i><span className="underline">komunikovat</span>, proč</i> vaše řešení je pro nakupujícího <span className="underline">nejlepší</span>.</Paragraph>
        <Paragraph><i>Vylíčit to</i>. Aby <b><span className="underline">chtěl</span></b> vaše řešení využít.</Paragraph>
        <Paragraph>A <i>jestli <span className="underline">vaše řešení</span></i> pro toho <i>konkrétního</i> člověka nejlepší NENÍ, tak mu to <b><i>řekněte</i></b>.</Paragraph>
        <Paragraph>Růstový marketing pomáhá <i><span className="underline">podnikatelům</span></i> a pomáhá i <span className="underline"><i>nakupujícím</i></span>!</Paragraph>
        <Heading2 className='marginTop'><i>PROTO</i> je <b>růstový marketing</b> dalším <i><span className="underline">(i vaším)</span></i> <b><span className="underline">nevyhnutelným</span></b> krokem.</Heading2>
        <Heading3><span className="underline"><i><b>A vy ho máte možnost využít doslova za PÁR KORUN!</b></i></span></Heading3>
        <Paragraph>Protože nejsme zatím <i>úplně velká <b>agentura</b>,</i></Paragraph>
        <Paragraph><b>NECHCEME 200.000Kč a 10% výdělku k tomu.</b></Paragraph>
        <Paragraph>I když <b><i><span className="underline">to tu cenu má!</span></i></b></Paragraph>
        <Paragraph>Za to, co by vás <i>jinde</i> stálo <b>těch 200.000Kč + 10%</b>,</Paragraph>
        <Paragraph>nám stačí <b>70.000Kč + 8%</b>.</Paragraph>
        <Paragraph><i>(samozřejmě výsledná cena záleží projekt od projektu)</i></Paragraph>
        <Heading3 className='architect marginTop'>“Cena to je skvělá, ale jak vám můžu věřit?”</Heading3>
        <Heading2>Protože jsme <i>šílenci</i> a <b>stojíme si za tím, že si NEZASLOUŽÍME peníze, dokud nedodáme <i>VÝSLEDKY</i>.</b></Heading2>
        <Heading1 className='secondary'>Pokud se nám NEPOVEDE udělat kampaně ziskově, <span>nezaplatíte</span> nám ANI KORUNU!</Heading1>
        <Heading2><i><span className="underline">Nemáte</span></i> tak co <span className="underline">ztratit</span>, jen <b>získat</b>.</Heading2>
        <Paragraph><b><span className="underline">Ani ne za POLOVINU</span></b> toho, co byste <i>normálně platili</i>,</Paragraph>
        <Paragraph>můžete <i>mít</i>, co teď akutně <span className="underline">potřebujete</span>.</Paragraph>
        <Paragraph><b><i>Větší</i></b> a <b><i>stabilnější</i></b> <b><span className="underline">příjem</span></b>, vyšší <b><span className="underline">návratnost investic</span></b>.</Paragraph>
        <Paragraph>Podnikání nabere větší <b><i>setrvačnost</i></b>.</Paragraph>
        <Paragraph>A začnou přicházet <b>platící zákazníci</b>.</Paragraph>
        <Paragraph>Takoví, co budou <b><i><span className="underline">nadšení, že jim může váš produkt nebo služba pomoct</span></i></b>.</Paragraph>
        <Heading3 className='architect marginTop'>“Jak začneme?”</Heading3>
        <Paragraph><i>Musíme</i> spolu <b><i><span className="underline">vytvořit nejefektivnější strategii.</span></i></b></Paragraph>
        <Paragraph><i>To</i> je teď to <b>hlavní</b>.</Paragraph>
        <Paragraph><i>Všechno ostatní</i> se bude <span className="underline">odvíjet</span> od toho.</Paragraph>
        <Heading2 className='marginTop'><span className="secondary">Naše šílenství nekončí.</span> My vám tu <span className="underline"><i>strategii uděláme</i> <b>ZDARMA</b></span>.</Heading2>
        <Paragraph><span className="secondary"><b><span className="underline">Nevěřte nám,</span> <i>dokud se nepředvedeme.</i></b></span></Paragraph>
        <Paragraph><i>Nechceme</i> vás <span className="underline">nutit</span> do <b>rozhodnutí</b>.</Paragraph>
        <Paragraph>Přece jen nás asi budete chtít <b><i>víc poznat</i></b>, než budeme spolupracovat.</Paragraph>
        <Paragraph>V tuhle chvíli <span className="underline">stačí</span>, abyste řekli <i>“možná...”</i>.</Paragraph>
        <Paragraph><b>Nechte si od nás vytvořit</b> vaši <i><span className="underline">strategii</span></i> a <span className="underline">KDYŽ</span> nebudete chtít, už s námi <span className="underline"><b>nemusíte</b> NIKDY</span> mluvit.</Paragraph>
        <Heading2 className='marginTop'><i>Zavoláme si</i> s vámi <i><span className="underline">přes internet</span></i>, <i>věnujeme</i> vám <span className="underline">náš čas</span> a <span className="underline"><i>vytvoříme</i> vám <b><i>růstovou strategii</i></b>.</span></Heading2>
        <Heading2>To všechno <b><i>zdarma</i></b> <span className="underline"><i>a co bude dál, je na vás</i></span>.</Heading2>
        <Heading1 className='WhatForMe__timeIsNow'>ČAS JE TEĎ</Heading1>
        <Heading3 className='WhatForMe__nextProject'><i>(dokud nenajdeme svůj další projekt - dnes, {formattedToday} jsme ještě volní)</i></Heading3>
        <div onClick={() => {setIsOrderActive(true)}} className='WhatForMe__CTA'><p>Dobře, <i>ukažte<br></br> se!&nbsp;</i> Vytvořte mi<br></br> růstovou stra-<br></br>tegii zdarma!</p></div>
        <Paragraph className='WhatForMe__bahamas'><i>“Protože jestli to bude fungovat, kupuju lístek na Bahamy pro celou rodinu!”</i></Paragraph>
        <Paragraph><b>PS:</b> Konkurence <span className="underline">nespí</span> a k růstovému marketingu se <b>dřív nebo později</b> dostane. Ten, který se k němu <i>dostane</i> první, <b><span className="underline">vyhraje všechno</span></b>. <i>Nebude cesty zpátky</i>.</Paragraph>
        <Paragraph><b>Teď</b> můžete <i><span className="underline">získat plán</span></i>, který pro vás může mít hodnotu <b><i><span className="underline">STOVEK TISÍC</span></i> korun</b>.</Paragraph>
        <Paragraph>Úplně <b>zdarma</b>, za <b><span className="underline">jednu</span> schůzku</b>.</Paragraph>
        <Paragraph><b><i>Neztratíte nic</i></b>.</Paragraph>
        <Paragraph><i><span className="underline">Za pokus to stojí</span></i>, nemám pravdu?</Paragraph>
        <Paragraph>Co <span className="underline">NEJHORŠÍHO</span> se může stát? A co <i><span className="underline">NEJLEPŠÍHO</span></i> se může stát?</Paragraph>
        <Paragraph>Rozhodnutí je <i>na vás</i>.</Paragraph>
        <Paragraph>Když se rozhodnete říct <i>“možná”</i>, může se změnit <span className="underline">všechno</span>.</Paragraph>
        <Paragraph>Kde <i>chcete</i> být <i><b>za rok?</b></i></Paragraph>
        <Paragraph>Kde <i>budete <b>za měsíc?</b></i></Paragraph>
        <Paragraph><i>Využijete</i> <b>příležitosti?</b></Paragraph>
    </div>
}

export default WhatForMe;





