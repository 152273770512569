import './Marketing.scss';

import scheme from '../../assets/scheme.png';
import schemeMobile from '../../assets/scheme-mobile.png';
import graph from '../../assets/graph.jpg';
import { useMediaQuery } from 'react-responsive';


import { Heading1 } from "../../UI/Heading1/Heading1";
import { Heading2 } from "../../UI/Heading2/Heading2";
import { Heading3 } from '../../UI/Heading3/Heading3';

interface MarketingProps {

}

export const Marketing: React.FC<MarketingProps> = () => {
    const isMobile = useMediaQuery({maxWidth: 700})
    return <div className="Marketing TopMarkers">
        <div className="TopMarker left"></div>
        <div className="TopMarker right"></div>
        <div className='Marketing__background'>
            <img src={graph}></img>
        </div>
        <Heading2 className='Marketing__heading2'>Bez <i>jedné</i> věci se úspěšné podnikání <span className='underline'>neobejde: <b>nakupující</b></span>.</Heading2>
        <Heading1 className='Marketing__heading1'>Jediný způsob, když jich dost nechodí samo nebo na doporučení, <span>je marketing.</span></Heading1>
        <img className='Marketing__img' src={isMobile ? schemeMobile : scheme}></img>
    </div>
}

export default Marketing;