import './Opportunity.scss';

import meme from '../../assets/meme.png';

import { Heading1 } from "../../UI/Heading1/Heading1";
import { Heading2 } from "../../UI/Heading2/Heading2";
import { Heading3 } from '../../UI/Heading3/Heading3';

interface OpportunityProps {

}

export const Opportunity: React.FC<OpportunityProps> = () => {
    return <div className="Opportunity">
        <Heading1 className='Opportunity__heading1 secondary'>Je to příležitost, co přijde <span>jednou za život</span> a co <span>zničí nepřipravené podnikatele!</span></Heading1>
        <img className='Opportunity__meme' src={meme}></img>
        <div className='Opportunity__benefits'>
            <p className='Opportunity__benefits__description'>Tahle metoda <span className='underline'><i>zlepšuje</i></span> a <b>chrání</b> před:</p>
            <div className='Opportunity__benefits__list'>
                <p className='Opportunity__benefit'>❌ <i>Ušlým</i> <b>příjmem</b></p>
                <p className='Opportunity__benefit'>❌ <i>Malou</i> <b>poptávkou</b></p>
                <p className='Opportunity__benefit'>❌ Vysokými <i><span className='underline'>náklady</span></i> na reklamu</p>
                <p className='Opportunity__benefit'>❌ <span className='underline'>Nízkou</span> <b>návratností</b></p>
                <p className='Opportunity__benefit'>❌ Nevyužítým <span className='underline'>potenciálem</span></p>
                <p className='Opportunity__benefit'>❌ <i>Navždy</i> <b>ztraceným časem</b></p>
            </div>
        </div>
        <Heading2 className='Opportunity__heading2'><b>NAOPAK!</b> <i>Katapultuje</i> 🚀 návratnost téměř <i><span className='underline'>každého</span></i> byznysu <b><span className='underline'>10x, 100x, 1000x...!</span></b></Heading2>
        <div className='Opportunity__benefits'>
            <p className='Opportunity__benefits__description'><span className='underline'>Za chvíli</span> se <b><i>dozvíte</i></b> jak</p>
            <div className='Opportunity__benefits__list'>
                <p className='Opportunity__benefit'> ✅ Mít <span className='underline'><i>ziskové reklamy</i></span></p>
                <p className='Opportunity__benefit'> ✅ Transformovat <i>byznys</i> na <b><span className='underline'>stroj na peníze</span></b></p>
                <p className='Opportunity__benefit'> ✅ Předlétnout <i>každou</i> <b>konkurenci</b></p>
                <p className='Opportunity__benefit'> ✅ Se <span className='underline'>stejnou</span> návštěvností získat <b><span className='underline'>více</span> poptávek</b></p>
            </div>
        </div>
    </div>
}

export default Opportunity;