import './Hi.scss';

import team from '../../assets/team.png';

import { Heading1 } from "../../UI/Heading1/Heading1";
import { Heading2 } from "../../UI/Heading2/Heading2";
import { Heading3 } from '../../UI/Heading3/Heading3';
import { Paragraph } from '../../UI/Paragraph/Paragraph';

interface HiProps {

}

export const Hi: React.FC<HiProps> = () => {
    return <div className="Hi SkewSection">
        <div className='Hi__left'>
            <Paragraph>Dobrý den, ahoj.</Paragraph>
            <Paragraph>Jsem rád, že tu zůstáváte. Jmenuji se Josef Macků (na fotce našeho týmu jsem ten se světlými vlasy).</Paragraph>
            <Paragraph>Několik let se snažím přijít na klíč k <i>EFEKTIVNÍMU</i> rozrůstání byznysů.</Paragraph>
            <Paragraph><i>Nápad =&gt; rozjetí =&gt; růst 🔁 nápad =&gt; rozjetí =&gt; růst 🔁 nápad =&gt; rozjetí =&gt; růst...</i></Paragraph>
            <Paragraph>Tahle stránka je o metodě, která změnila všechno.</Paragraph>
            <Paragraph>Změnila zahraniční trh, změnila můj byznys a <span className="underline">změnila můj život</span>.</Paragraph>
        </div>
        <div className='Hi__right'>
            <img src={team}></img>
            <p className='Hi__description'>AmnioM tým</p>
        </div>
    </div>
}

export default Hi;