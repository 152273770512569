import './Header.scss';

import cash from '../../assets/cash.jpeg';

import { Heading1 } from "../../UI/Heading1/Heading1";
import { Heading2 } from "../../UI/Heading2/Heading2";
import { Heading3 } from '../../UI/Heading3/Heading3';

interface HeaderProps {

}

export const Header: React.FC<HeaderProps> = () => {
    return <div className="Header BottomMarkers">
        <div className='BottomMarker left'></div>
        <div className='BottomMarker right'></div>
        <div className='Header__background'>
            <img src={cash} className='Header__img'>

            </img>
        </div>
        <div className='Header__challenge'><p>RYCHLE, NEŽ TO OBJEVÍ <span className='underline'>KONKURENCE!</span></p></div>
        <Heading1 className="Header__heading1">Zahraniční metoda, co dělá z podnikatelů milionáře...</Heading1>
        <Heading2 className='Header__heading2'>... pašujeme ji do Česka! Změnila <span>zahraniční trh</span> a <b>změní</b> podnikání i U NÁS!</Heading2>
        <Heading3 className='Header__heading3'>Dejte mi pár sekund to vysvětlit.</Heading3>
    </div>
}

export default Header;