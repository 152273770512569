import './FunWork.scss';

import people from '../../assets/people.jpg';

import { Heading1 } from "../../UI/Heading1/Heading1";
import { Heading2 } from "../../UI/Heading2/Heading2";
import { Heading3 } from '../../UI/Heading3/Heading3';

interface FunWorkProps {

}

export const FunWork: React.FC<FunWorkProps> = () => {
    return <div className="FunWork TopMarkers BottomMarkers">
        <div className="FunWork__background">
            <img src={people} alt="" />
        </div>
            <div className="BottomMarker left"></div>
            <div className="BottomMarker right"></div>
            <div className="TopMarker right"></div>
            <div className="TopMarker left"></div>
            <Heading1>Nevím, jak vás, ale mě tahle práce <span>baví</span>!</Heading1>
            <Heading2><b>Ale</b> chápu, že <i>ne každý</i>, se v ní najde.</Heading2>
            <Heading3>Dnes vám tu předkládám <span className='underline'>jedinečnou</span> příležitost.</Heading3>
            <Heading1 className='FunWork__rhombus'>Se svým týmem zrovna hledám další projekt, do kterého se pustit.</Heading1>
    </div>
}

export default FunWork;