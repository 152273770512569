import './Secrets.scss';

import cash from '../../assets/cash.jpeg';

import { Heading1 } from "../../UI/Heading1/Heading1";
import { Heading2 } from "../../UI/Heading2/Heading2";
import { Heading3 } from '../../UI/Heading3/Heading3';
import { Paragraph } from '../../UI/Paragraph/Paragraph';

interface SecretsProps {

}
















export const Secrets: React.FC<SecretsProps> = () => {
    return <div className="Secrets">
        <Heading1>Tajemství #1</Heading1>
        <Heading2><i>Představte si</i>, že jdete na <span className='underline'>první</span> rande, a ona se vás zeptá, <span className='underline'><i>jestli s ní budete mít děti</i></span>.</Heading2>
        <Paragraph>Řeknete NE!</Paragraph>
        <Paragraph>Přitom tohle dělá <i>VĚTŠINA</i> podnikatelů!</Paragraph>
        <Paragraph><span className='secondary'>Zaplatí si za reklamu, kde chtějí, aby si <i>náhodný</i> člověk <span className='underline'>HNED nakoupil</span>.</span></Paragraph>
        <Paragraph><b>Mluví o sobě.</b> O <i>jejich</i> produktech, o <i>jejich</i> službách.</Paragraph>
        <Paragraph>Jenže <b><i><span className='underline'>lidi se zajímají jenom o sebe!</span></i></b></Paragraph>
        <Paragraph>Váš produkt je <b><i>nezajímá</i></b>, zajímá je řešení <b><span className='underline'>jejich problému</span>!</b></Paragraph>
        <Paragraph>Když někdo začne nabízet <i>svůj</i> produkt hned při prvním setkání, <i><span className='underline'>mají pocit</span></i>, že se na nich chce jenom VYDĚLAT.</Paragraph>
        <Paragraph><i>Svůj</i> produkt byste <b>neměli VŮBEC zmiňovat</b>, dokud si k vám zákazník <b>nevybudoval <i><span className='underline'>důvěru</span></i></b>.</Paragraph>
        <Heading3>“Jak mám teda dělat reklamu?”</Heading3>
        <Paragraph>Prvně zákazníkovi <span className='underline'>pomožte</span> a <i>nic za to nechtějte</i>.</Paragraph>
        <Paragraph>A myslím tím <span className='underline'>OPRAVDU</span> pomožte!</Paragraph>
        <Paragraph>Budete se cítit, že <i>dáváte konkurenci</i> možnost to <span className='underline'>obšlehnout</span>.</Paragraph>
        <Paragraph>Budete mít pocit, že pak <i>návštěvník</i> nebude mít <i><b>důvod</b></i> <span className='underline'>nakupovat</span>.</Paragraph>
        <Paragraph>Ale vy přece chcete <i><span className='underline'>upřímně</span> pomoct</i>, ne?</Paragraph>
        <Paragraph>V tu chvíli <b>zákazník uvidí</b> dvě věci:<br></br>✅ že mu <i>opravdu <span className='underline'>chcete</span></i> pomoct a<br></br>✅ že <i>opravdu <span className='underline'>víte</span>, jak</i> mu pomoct.</Paragraph>
        <Paragraph>A tak <b><i>začne zvažovat nákup!</i></b></Paragraph>
        <Heading1>Tajemství #2</Heading1>
        <Heading2>Líný <i>(jako většina lidí)</i> <span className='underline'>zákazník</span>, přijde na <b>váš web</b>, který <b>nikdy neviděl,</b> a vy mu <i>řeknete:</i></Heading2>
        <Heading2 className='secondary'>“<i><span className='underline'>Zorientuj</span> se tady a až <span className='underline'>přijdeš na to, kde najdeš</span>, co potřebuješ, a <span className='underline'>kam kliknout</span>, tak mi můžeš <b>zaplatit</b>.</i>”</Heading2>
        <Paragraph>BLBOST!</Paragraph>
        <Paragraph>A stejně to tak <b>95% podnikatelů</b> <span className='underline'>dělá</span>!</Paragraph>
        <Paragraph>Proto na západě vznikl <b><i><span className='underline'>koncept konverzních trychtýřů</span></i></b>.</Paragraph>
        <Paragraph>Jsou to zpravidla <span className='underline'>jednostránkové weby</span>, které <i><span className='underline'>provedou</span></i> návštěvníka obsahem, <b>který potřebuje...</b></Paragraph>
        <Paragraph><b>... a dovedou ho až k nákupu!</b></Paragraph>
        <Heading1>Tajemství #3</Heading1>
        <Heading2>Myslíte, že se <span className='underline'><i>lidi</i></span> rozhodují <b>logicky</b>?</Heading2>
        <Paragraph>Částečně možná.</Paragraph>
        <Paragraph>Ale bez emoce <b>NEUDĚLAJÍ akci!</b></Paragraph>
        <Paragraph>Proto vaše <i>reklama</i>, vaše <i>pomoc</i> a váš <i>trychtýř</i> <i><b><span className='underline'>musí vzbudit emoce</span></b></i>.</Paragraph>
        <Heading2 className='Secrets__now'>Když <span className='underline'>teď</span> začnete <b><i>používat tato 3 tajemství</i></b>. Jaké<b> by to bylo?</b></Heading2>
        <Heading2 className='secondary'>Už jen s těmito <i>3 radami</i> <b>předběhnete</b> v Česku  <b><span className='underline'>80% konkurence</span>!</b></Heading2>
        <Heading2><i>(A na západním trhu budete životaschopný byznys -  to bude za pár let <b>platit i u nás</b>.)</i></Heading2>
        <Heading1><span className='secondary'>POZOR! Těch tajemství je mnohem víc!</span> Tajemství #4</Heading1>
        <Heading2>Většina podnikatelů míří <i>na lidi</i>, co <b>už jsou připravení</b> nakupovat. Jenže <i><span className='underline'>těch jsou jen asi 3%!</span></i></Heading2>
        <Paragraph>Dalších <span className='underline'>97%</span> potenciálních zákazníků <span className='underline'><i>NEVÍ</i></span>, že službu/produkt <i>potřebují</i>!</Paragraph>
        <Paragraph>Vy můžete být ten, který jim <b><i><span className='underline'>ukáže, jak jejich problém vyřešit</span></i></b>.</Paragraph>
        <Paragraph>Tím si k vám<br></br>✅ udělají <b>dobrý vztah</b> a<br></br>✅ vybudují <b><i>důvěru</i></b>.</Paragraph>
        <Paragraph>A jakmile budou připravení, <b><i>koupí <span className='underline'>od vás</span>!</i></b></Paragraph>
        <Heading1>Tajemství #5</Heading1>
        <Heading2>Hodně podnikatelů si <i>myslí</i>, že nemají objednávky, <i>protože</i> na jejich web chodí <b>málo lidí.</b></Heading2>
        <Paragraph>Ale v dnešní době <span className='underline'>už není problém</span> dostat <i>lidi na web.</i></Paragraph>
        <Paragraph>Je <b>problém</b> je <span className='underline'><i>přetavit do kupujících</i></span>.</Paragraph>
        <Paragraph>A tak se přišlo s <b>metodou</b>, kdy se <span className='underline'><b><i>testují různé verze stránky</i></b> s různými psychologickými přístupy</span>.</Paragraph>
        <Paragraph><i>Systematicky a <span className='underline'>vědecky</span></i> se tak <b>zvyšuje konverzní poměr</b>.</Paragraph>
        <Heading1>Tajemství #6</Heading1>
        <Heading2>S <b><i>průměrnou</i></b> nabídkou <i>nelze</i> čekat <b><i>nadprůměrný zájem</i></b>.</Heading2>
        <Paragraph><i>Vaše</i> nabídka <b>musí být</b> <span className='underline'>NEODOLATELNÁ</span>!</Paragraph>
        <Paragraph>Tak <b><i><span className='underline'>úžasná, že jen blázen by ji odmítnul.</span></i></b></Paragraph>
        <Paragraph>Ve chvíli, kdy <span className='underline'>toto</span> dokážete, <i>roznesete <b>konkurenci</b></i>, a ta vás bude <i><b>prosit o milost!</b></i></Paragraph>
        <Heading3>“Ale já nenabízím něco nadprůměrného...”</Heading3>
        <Paragraph>To je <b>problém!</b> Ale <b><i>nevadí!</i></b></Paragraph>
        <Paragraph>Buď můžete <i>zapřemýšlet</i>, jak vytvořit <i><span className='underline'>mnohem lepší nabídku</span></i>, na kterou se budou stát <b>fronty jak za covidu na toaleťák,</b></Paragraph>
        <Paragraph>nebo ji prostě <i>podejte</i> tak, že <b><span className='underline'>VYZNÍ úžasně</span>.</b></Paragraph>
        <Paragraph>Zase je to <b>celé o psychologii!</b></Paragraph>
    </div>
}

export default Secrets;