import './NewMethod.scss';

import library from '../../assets/library.jpg';

import { Heading1 } from "../../UI/Heading1/Heading1";
import { Heading2 } from "../../UI/Heading2/Heading2";
import { Heading3 } from '../../UI/Heading3/Heading3';
import { Paragraph } from '../../UI/Paragraph/Paragraph';

interface NewMethodProps {

}






















export const NewMethod: React.FC<NewMethodProps> = () => {
    return <div className="NewMethod TopMarkers BottomMarkers">
        <div className="TopMarker left"></div>
        <div className="TopMarker right"></div>
        <div className='BottomMarker left'></div>
        <div className='BottomMarker right'></div>
        <div className='NewMethod__background'>
            <img src={library}></img>
        </div>
        <Heading2>DIGITÁLNÍ MARKETING <span className='underline'>NENÍ</span> JENOM REKLAMA</Heading2>
        <Heading1>X TAJEMSTVÍ NOVÉ METODY (a brzo jediné funkční)</Heading1>
        <Paragraph>Než se do toho pustíme, chci, abyste <i>chápali <span className='underline'>toto:</span></i></Paragraph>
        <Paragraph>✅ <i>Správný</i> <b>marketing</b> je <b>PŘEDVÍDATELNÝ</b>. ⬅️</Paragraph>
        <Paragraph>Vložíte 1Kč, <i>vrátí se vám</i> 10Kč.</Paragraph>
        <Paragraph>Marketing musí být <span className='underline'>VŽDY</span> ziskový.</Paragraph>
        <Paragraph>✅ Celé to je o <b><i>psychologii</i></b>. ⬅️</Paragraph>
        <Paragraph><b>Kupující jsou lidi.</b> Ať už prodáváte B2C nebo B2B.</Paragraph>
        <Paragraph>Lidi fungují takto:</Paragraph>
        <Paragraph><i>Příchozí informace =&gt; zpracování informace =&gt; akce.</i></Paragraph>
        <Paragraph>Fáze zpracování informace je <span className='underline'>čistá psychologie</span>.</Paragraph>
        <Paragraph>Pomocí psychologie přesvědčíte KOHOKOLI k ČEMUKOLI.</Paragraph>
        <Paragraph><b>I návštěvníka stát se nakupujícím.</b></Paragraph>
    </div>
}

export default NewMethod;