import './TraditionalMarketing.scss';

import cash from '../../assets/cash.jpeg';

import { Heading1 } from "../../UI/Heading1/Heading1";
import { Heading2 } from "../../UI/Heading2/Heading2";
import { Heading3 } from '../../UI/Heading3/Heading3';
import { Paragraph } from '../../UI/Paragraph/Paragraph';

interface TraditionalMarketingProps {

}











export const TraditionalMarketing: React.FC<TraditionalMarketingProps> = () => {
    return <div className="TraditionalMarketing">
       <Heading1>TRADIČNÍ <span><i>(zastaralý)</i></span> marketing:</Heading1>
       <Heading2><b>#1 <span className='underline'>Vytvoří</span></b> se reklama</Heading2>
       <Paragraph>Sami nebo s pomocí agentury si necháte <i>vytvořit</i> reklamu.</Paragraph>
       <Paragraph>Nějaký text...</Paragraph>
       <Paragraph>Obrázek nebo video.</Paragraph>
       <Heading2><b>#2</b> Reklama se <b><span className='underline'>spustí</span></b></Heading2>
       <Paragraph>Vybere se nějaká platforma.</Paragraph>
       <Paragraph>Facebook, instagram, google...</Paragraph>
       <Paragraph>A ta začne z vaší kreditní karty nasávat peníze.</Paragraph>
       <Heading2><b>#3</b> Něco to dělá</Heading2>
       <Paragraph>Nějací lidi se prokliknou na vaši <i>webovku.</i></Paragraph>
       <Paragraph>Ale vy z toho stejně máte pocit, že <span className='underline'>vyhazujete peníze oknem</span>.</Paragraph>
       <Heading2>A to je <span className='underline'>všechno</span>. <b><i>Jednoduché, že?</i></b></Heading2>
       <Heading1>A na západě <span>NEFUNKČNÍ!</span></Heading1>
       <Heading2>Už <b>i u nás</b> takový marketing <span className='underline'><i>ztrácí na účinnosti</i></span>. Ten, kdo začne používat <b><i>novou metodu</i></b>, <span className='secondary'>získá nejen <b>víc poptávek</b></span>, než zvládne vyřídit. <span className='secondary'><span className='underline'>Sebere</span> poptávky</span> i veškeré KONKURENCI!</Heading2>
       <Heading2>Správně udělaný <span className='underline'>marketing</span> dělí <b>MILIONÁŘE</b> od <span className='underline'><i>podnikatelů, co nikdy nemají čas</i></span>.</Heading2>
    </div>
}

export default TraditionalMarketing;