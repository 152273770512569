import './Pockets.scss';

import team from '../../assets/team.png';

import { Heading1 } from "../../UI/Heading1/Heading1";
import { Heading2 } from "../../UI/Heading2/Heading2";
import { Heading3 } from '../../UI/Heading3/Heading3';
import { Paragraph } from '../../UI/Paragraph/Paragraph';

import SamOvens from '../../assets/sam-ovens.png';
import SabriBook from '../../assets/sabri-book.png';
import SabriSuby from '../../assets/sabri-suby.png';
import JimEdwards from '../../assets/jim-edwards.png';
import RusselBrunson from '../../assets/russel-brunson.png';
import DanKennedy from '../../assets/dan-kennedy.png';

interface PocketsProps {

}

export const Pockets: React.FC<PocketsProps> = () => {
    return <div className="Pockets">
        <Heading1 className='Pockets__heading1'>Přišli s tím a otekly jim kapsy</Heading1>
        <div className='Pockets__pyramid'>
            <div className='Pockets__pyramid__background'></div>
            <div className='Pockets__pyramid__row'>
                <div className='Pockets__pyramid__person Pockets__pyramid__person--brunson'>
                    <div className="Pockets__pyramid__person__image">
                        (fotografie není volně dostupná)
                        <img className='Pockets__pyramid__person__image__image' src={RusselBrunson}></img>
                    </div>
                    <p className='Pockets__pyramid__description'>Russel Brunson</p>
                </div>
                <div className='Pockets__pyramid__person Pockets__pyramid__person--kennedy'>
                    <div className="Pockets__pyramid__person__image">
                        (fotografie není volně dostupná)
                        <img className='Pockets__pyramid__person__image__image' src={DanKennedy}></img>
                    </div>
                    <p className='Pockets__pyramid__description'>Dan Kennedy</p>
                </div>
            </div>
            <div className='Pockets__pyramid__row'>
                <div className='Pockets__pyramid__person'>
                    <img className='Pockets__pyramid__person__image' src={SamOvens}></img>
                    <p className='Pockets__pyramid__description'>Sam Ovens</p>
                    <p className="Pockets__pyramid__source">zdroj fotografie <a rel="noreferrer" target="_blank" href="https://commons.wikimedia.org/wiki/File:Sam_Ovens_photo.jpg">zde</a></p>
                </div>
                <div className='Pockets__pyramid__person'>
                    <img className='Pockets__pyramid__person__image' src={SabriSuby}></img>
                    <p className='Pockets__pyramid__description'>Sabri Suby</p>
                    <p className="Pockets__pyramid__source">zdroj fotografie <a rel="noreferrer" target="_blank" href="https://commons.wikimedia.org/wiki/File:Sabri_Suby.jpg">zde</a></p>
                </div>
                <div className='Pockets__pyramid__person Pockets__pyramid__person--edwards'>
                    <div className="Pockets__pyramid__person__image">
                        (fotografie není volně dostupná)
                        <img className='Pockets__pyramid__person__image__image' src={JimEdwards}></img>
                    </div>
                    <p className='Pockets__pyramid__description'>Jim Edwards</p>
                </div>
            </div>
        </div>
        <Paragraph className='Pockets__p'>
            Tihle přinesli <i><span>největší marketingový průlom</span></i> v digitálním marketingu.
        </Paragraph>
        <Paragraph className='Pockets__p'><b>Například Sabri Suby.</b></Paragraph>
        <Heading2 className='Pockets__heading2'>Tento muž udělal ze své marketingové agentury <span className='secondary'><span className='underline'>nejrychleji rostoucí</span> marketingovou agenturu</span> <b>v Austrálii!</b></Heading2>
        <div className='Pockets__SabriSuby'>
            <div className='Pockets__SabriSuby__left'>
                <img className='Pockets__SabriSuby__img' src={SabriBook}></img>
                <p className='Pockets__SabriSuby__description'>Sabri Suby</p>
                <p className="Pockets__SabriSuby__source">převzato pod CC licencí z <a rel="noreferrer" target="_blank" href="https://commons.wikimedia.org/wiki/File:Sabri_Suby.jpg">tohoto zdroje</a></p>
            </div>
            <div className="Pockets__SabriSuby__right">
                <Paragraph><b>Sabri Suby</b> vyrůstal <i>bez</i> otce. Jeho matka musela mít <span className='underline'>několik</span> zaměstnání, aby <i>uživila</i> rodinu.</Paragraph>
                <Paragraph>Inspirovala ho i proto, že přes hodně práce si na něj udělala vždycky čas.</Paragraph>
                <Paragraph>Sabri začal velmi brzo <b>podnikat</b> a <i>studovat <span className='underline'>marketing.</span></i></Paragraph>
                <Paragraph>Čerpal z Russela Brunsona a Dana Kennedyho. Jakmile metodu poznal a naučil se ji používat, <b>za 4 roky se dostal z 0 na $10.000.000!</b></Paragraph>
                <Paragraph>Metodu dál <i>vylepšoval</i> a sám marketingu <i><span className='underline'>neocenitelně</span></i> přispěl.</Paragraph>
            </div>
        </div>
        <Heading2 className='Pockets__heading2'>S ostatními osobnostmi přišli na to, jak <span className='underline'><i>extrémně efektivně</i> zvýšit <b>návratnost marketingu.</b></span></Heading2>
        <Heading2 className='Pockets__heading2'>Dnes se <span className='secondary'>metoda stává na západě STANDARDEM</span> a <b>zpomalené byznysy</b>, co ji nevyužívají, kvůli obrovské ceně reklamy <b><span className='underline'>krachují!</span></b></Heading2>
    </div>
}

export default Pockets;