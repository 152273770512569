import './DoesNotExist.scss';

import cash from '../../assets/cash.jpeg';

import { Heading1 } from "../../UI/Heading1/Heading1";
import { Heading2 } from "../../UI/Heading2/Heading2";
import { Heading3 } from '../../UI/Heading3/Heading3';

interface DoesNotExistProps {

}

export const DoesNotExist: React.FC<DoesNotExistProps> = () => {
    return <div className="DoesNotExist">
       <Heading3 className='DoesNotExist__heading3'>“Nic takového neexistuje!”</Heading3>
       <Heading2 className='DoesNotExist__heading2'><b>Existuje.</b> Brzy zjistíte, co vám <i><span className='underline'>změní</span> byznys i život.</i></Heading2>
       <Heading2 className='DoesNotExist__heading2'><span className='underline'>POZOR!</span> Když nebudete <i>rychlí</i>, <b>náskoku</b> se chytí <b><span className='underline'>konkurence!</span></b></Heading2>
    </div>
}

export default DoesNotExist;