import { useCallback, useState } from 'react';
import axios from 'axios';

import './Mail.scss';

import whisper from '../../assets/whisper.jpg';
import { CTA } from '../../UI/CTA/CTA';
import { Heading1 } from "../../UI/Heading1/Heading1";
import { Heading2 } from "../../UI/Heading2/Heading2";
import { Heading3 } from '../../UI/Heading3/Heading3';
import { Paragraph } from '../../UI/Paragraph/Paragraph';

interface MailProps {
    popup: (text: string, isError: boolean, isSuccess?: boolean) => void
}
export const validateEmail: (v: string) => boolean = (email: string) => {
    return !!String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

export const Mail: React.FC<MailProps> = ({ popup }) => {
    const [email, setEmail] = useState('');
    const [CTAWasClicked, setCTAWasClicked] = useState(false);

    const onCTAClicked = useCallback(() => {
        if (CTAWasClicked) {
            return;
        }    
        if (!validateEmail(email)) {
            popup("Zkontrolujte prosím, zda jste zadali mail správně", true);
            return;
        }
        setCTAWasClicked(true);
        window.gtag && window.gtag("event", "generate_lead");
        window.fbq && window.fbq("track", "Contact");
        //@ts-ignore-next-line
        window.ttq && window.ttq.track('Subscribe');
        axios.post(process.env.REACT_APP_BACKEND_URL + '/mailList', { email: email })
            .then(() => {
                setCTAWasClicked(false);
                popup("Úspěch, těšte se!", false, true);
                setEmail('');
            })
            .catch(e => {
                setCTAWasClicked(false);
                const err = e.response.data.err as string;
                if (err) {
                    if (err === 'mail-err') {
                        popup("Zkontrolujte prosím, zda jste zadali mail správně", true);
                        return;
                    }
                }
                popup("Došlo k chybě, prosím zkuste to znovu později. Nebo nám napište na team@amniomgrowth.com.", true);
            })
    }, [email, setEmail]);

    return <div className="Mail">
        <div className='Mail__background'>
            <img src={whisper}></img>
        </div>
        <Heading1>Chci dostávat další taková tajemství do mailu!</Heading1>
        <input value={email} onChange={e => setEmail(e.target.value)} type="email" placeholder='ovladnu@cely-trh.cz'></input>
        <CTA className={`Mail__CTA ${CTAWasClicked ? 'loading' : ''}`} onClick={onCTAClicked}>{CTAWasClicked ? "Počkejte chvilku, prosím" : "CHCI TAKOVÁ TAJEMSTVÍ!"}</CTA>
        <Paragraph><i>a PŘEDBĚHNOUT</i> veškerou konkurenci!</Paragraph>
        <Paragraph>odesláním souhlasíte s <a target="_blank" href="GDPR.pdf">ochranou osobních údajů</a></Paragraph>
    </div>
}

export default Mail;