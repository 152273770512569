import './Today.scss';

import cash from '../../assets/cash.jpeg';

import { Heading1 } from "../../UI/Heading1/Heading1";
import { Heading2 } from "../../UI/Heading2/Heading2";
import { Heading3 } from '../../UI/Heading3/Heading3';

interface TodayProps {

}

export const Today: React.FC<TodayProps> = () => {
    return <div className="Today">
       <Heading1 className='Today__heading1'>Dnes se dozvíte, jak zachránit a rozrůst váš BYZNYS...</Heading1>
       <Heading2 className='Today__heading2'>...pomocí metody, co z <i>normálních podnikatelů</i> dělá v Česku <b>milionáře</b> a v Americe bez ní <b><span className="underline">krachují!</span></b></Heading2>
    </div>
}

export default Today;