import './Order.scss';
import { useState, useCallback, useEffect, Dispatch, SetStateAction } from 'react';
import axios from 'axios';

import cash from '../../assets/cash.jpeg';

import { Heading1 } from "../../UI/Heading1/Heading1";
import { Heading2 } from "../../UI/Heading2/Heading2";
import { Heading3 } from '../../UI/Heading3/Heading3';
import { CTA } from '../../UI/CTA/CTA';

interface OrderProps {
    popup: (text: string, isError: boolean, isSuccess?: boolean) => void
    setIsOrderActive: Dispatch<SetStateAction<boolean>>
}

export const validateEmail: (v: string) => boolean = (email: string) => {
    return !!String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

export const validateFirstName: (v: string) => boolean = (name: string) => {
    if (!(name.trim().length >= 2)) {
        return false;
    }
    return true;
}


export const Order: React.FC<OrderProps> = ({ popup, setIsOrderActive }) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [checked, setChecked] = useState(false);
    const [CTAWasClicked, setCTAWasClicked] = useState(false);

    const onCTAClicked = useCallback(() => {
        if (CTAWasClicked) {
            return;
        }
        if (!checked) {
            popup("Prosím, odsouhlaste zpracování osobních údajů", true);
            return;
        }
        if (!validateEmail(email)) {
            popup("Zkontrolujte prosím, zda jste zadali mail správně", true);
            return;
        }
        if (!validateFirstName(name)) {
            popup("Jméno musí mít minimálně dvě písmena", true);
            return;
        }
        window.gtag && window.gtag("event", "contact");
        window.fbq && window.fbq("track", "Lead");
        //@ts-ignore-next-line
        window.ttq && window.ttq.track("Contact");
        setCTAWasClicked(true);
      
        axios.post(process.env.REACT_APP_BACKEND_URL + '/meeting', {
            name,
            email
        })
            .then(() => {
                setCTAWasClicked(false);
                popup("Hotovo, ozveme se!", false, true);
                setEmail('');
                setName('');
                setIsOrderActive(false);
            })
            .catch(e => {
                setCTAWasClicked(false);
                const err = e.response.data.err as string;
                if (err) {
                    if (err === 'mail-err') {
                        popup("Zkontrolujte prosím, zda jste zadali mail správně", true);
                        return;
                    }
                    if (err === 'name-err') {
                        popup("Jméno musí mít minimálně dvě písmena", true);
                        return;
                    }
                }
                popup("Došlo k chybě, prosím zkuste to znovu později. Nebo nám napište na team@amniomgrowth.com.", true);
            });
    }, [name, setName, email, setEmail, checked, CTAWasClicked, setCTAWasClicked])
    
    return <div className="Order">
        <div className='Order__content'>
            <div onClick={() => { setIsOrderActive(false) }} className='Order__cross'></div>
            <label htmlFor='name'>Vaše křestní jméno</label>
            <input className='Order__textInput' value={name} onChange={(e) => { setName(e.target.value) }} type="text" name="" id="name" />
            <label htmlFor="email">Váš e-mail</label>
            <input className='Order__textInput' value={email} onChange={(e) => setEmail(e.target.value)} type="email" name="" id="email" />
            <div className='Order__checkboxInput'>
                <label className={['Order__checkboxInput__label', checked ? 'Order__checkboxInput__label--checked' : ''].join(' ')}>
                    <input type='checkbox' onChange={(e) => { setChecked(v => !v) }} className='Order__checkboxInput__input'></input>
                </label>
                <p className='Order__checkboxInput__text'>Souhlasím se <a target="_blank" href="GDPR.pdf">zpracováním osobních údajů</a></p>
            </div>
            <CTA className={CTAWasClicked ? 'loading' : ''} onClick={onCTAClicked}>{CTAWasClicked ? "Počkejte chvilku, prosím" : "Chci růstovou strategii"}</CTA>
            <Heading3>Napíšeme, domluvíme si schůzku. Získáte to, po čem konkurence touží a nemá tušení, že to existuje</Heading3>
        </div>
    </div>
}

export default Order;