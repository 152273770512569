import './YouAsk.scss';

import { Heading1 } from "../../UI/Heading1/Heading1";
import { Heading2 } from "../../UI/Heading2/Heading2";
import { Heading3 } from '../../UI/Heading3/Heading3';

interface YouAskProps {

}

export const YouAsk: React.FC<YouAskProps> = () => {
    return <div className="YouAsk">
        <Heading1 className='YouAsk__YouAsk'>Ptáte se</Heading1>
        <Heading3 className='YouAsk__question'>“Co to povídáte???”,</Heading3>
        <Heading1 className='YouAsk__question2'>když to řeknu slušně?</Heading1>
        <Heading2 className='YouAsk__answer'>Tohle bude pro <span>váš byznys</span> nejdůležitější text, který jste na <b>posledních pět let</b> četli!</Heading2>
    </div>
}

export default YouAsk;