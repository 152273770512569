import { useState, useCallback } from "react";

import Popup from "./UI/Popup/Popup";
import { Header } from "./sections/Header/Header";
import YouAsk from "./sections/YouAsk/YouAsk";
import EasyThing from "./sections/EasyThing/EasyThing";
import Today from "./sections/Today/Today";
import Hi from "./sections/Hi/Hi";
import Pockets from "./sections/Pockets/Pockets";
import Opportunity from "./sections/Opportunity/Opportunity";
import DoesNotExist from "./sections/DoesNotExist/DoesNotExist";
import Marketing from "./sections/Marketing/Marketing";
import TraditionalMarketing from "./sections/TraditionalMarketing/TraditionalMarketing";
import NewMethod from "./sections/NewMethod/NewMethod";
import Secrets from "./sections/Secrets/Secrets";
import Mail from "./sections/Mail/Mail";
import WhatToDo from "./sections/WhatToDo/WhatToDo";
import FunWork from "./sections/FunWork/FunWork";
import WhatForMe from "./sections/WhatForMe/WhatForMe";
import Order from "./sections/Order/Order";

export const App = () => {
  const [popupText, setPopupText] = useState<string | null>(null);
  const [popupError, setPopupError] = useState<boolean>(false);
  const [popupSuccess, setPopupSuccess] = useState<boolean>(false);

  const popup = useCallback((text: string, isError: boolean = false, isSuccess?: boolean) => {
    setPopupError(isError);
    setPopupSuccess(!!isSuccess);
    setPopupText(text);
  }, []);

  const [orderActive, setIsOrderActive] = useState(false);

  return (
    <div className="App" style={{ overflow: 'hidden' }}>
      {orderActive && <Order setIsOrderActive={setIsOrderActive} popup={popup}></Order>}
      <Header></Header>
      <YouAsk></YouAsk>
      <EasyThing></EasyThing>
      <Today></Today>
      <Hi></Hi>
      <Pockets></Pockets>
      <Opportunity></Opportunity>
      <DoesNotExist></DoesNotExist>
      <Marketing></Marketing>
      <TraditionalMarketing></TraditionalMarketing>
      <NewMethod></NewMethod>
      <Secrets></Secrets>
      <Mail popup={popup}></Mail>
      <WhatToDo></WhatToDo>
      <FunWork></FunWork>
      <WhatForMe setIsOrderActive={setIsOrderActive}></WhatForMe>
      {!orderActive &&
        <div className="Footnote">
          <a target="_blank" href="GDPR.pdf" className="Footnote__a">Ochrana osobních údajů</a> | <a target="_blank" className="Footnote__a" href="https://www.amniomweb.cz">© AmnioM Web s.r.o. </a> (IČO: 11667052) | <a target="_blank" className="Footnote__a" href="https://www.amniomweb.cz">Vytvořil AmnioM Web</a>
        </div>
      }
      {popupText && <Popup isSuccess={popupSuccess} isError={popupError} onDismiss={() => { setPopupText(null); setPopupError(false) }} text={popupText} ></Popup>}
    </div>
  );
};
